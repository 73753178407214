import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './pages/Login';
import CheckOTP from './pages/CheckOTP';
import ListPage from './pages/ListPage';
import OrderDetail from './pages/OrderDetail';


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/check-otp" element={<CheckOTP />} />
        <Route path="/list-page" element={<ListPage />} />
        <Route path="/package" element={<OrderDetail />} />
      </Routes>
    </Router>
  );
}

export default App;
